import { paths } from "../../../api-engine/definitions/self-driving-definitions";
import { Workers } from "../../utils/service-url";
import { ApiResponse } from "./client";
import { WorkerApiService } from "./worker";

export type MultiformatPhotos =
  paths["/multiformat/{formatId}/photos"]["post"]["responses"]["200"]["content"]["application/json"];

export type MultiformatIcons =
  paths["/multiformat/{formatId}/icons"]["post"]["responses"]["200"]["content"]["application/json"];

export class SelfDrivingService extends WorkerApiService {
  module = "self-driving" as Workers;

  async photos(
    formatId: string,
    description: string,
    gptModel?: "ga" | "alpha",
  ): Promise<ApiResponse<{ photos: MultiformatPhotos["photos"] }>> {
    const settings = new URLSearchParams();
    if (gptModel) settings.set("model", gptModel);

    return await this.post(`multiformat/${formatId}/photos?${settings.toString()}`, {
      description,
    });
  }

  async icons(
    formatId: string,
    description: string,
    gptModel?: "ga" | "alpha",
  ): Promise<ApiResponse<{ icons: MultiformatIcons["icons"] }>> {
    const settings = new URLSearchParams();
    if (gptModel) settings.set("model", gptModel);

    return await this.post(`multiformat/${formatId}/icons?${settings.toString()}`, {
      description,
    });
  }
}

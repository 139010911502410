import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Chip,
  Fab,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { multiformatAtom } from "../multiformat/store/multiformat-atom";
import { Asset } from "./server/digital-asset-service";
import { ZoomInRounded } from "@mui/icons-material";

export const AssetCard: FC<{ asset: Asset; selected?: boolean } & CardProps> = ({
  asset,
  selected,
  ...props
}) => {
  const [hover, setHovering] = useState<boolean>(false);

  const multiformat = useAtomValue(multiformatAtom);
  const ceAppName = multiformat
    ? (multiformat["content-types"].find((c) => c["Content Type"] === asset.content_type)?.[
        "CE App Name"
      ] ?? asset.content_type)
    : undefined;

  return (
    <Card
      variant="outlined"
      {...props}
      sx={selected ? { borderWidth: "4px", borderColor: "primary.main" } : {}}
    >
      <CardActionArea
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Box
          sx={{
            height: "200px",
            backgroundImage: `url(${asset.thumbnail_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selected && hover && (
            <Fab color="primary">
              <ZoomInRounded />
            </Fab>
          )}
        </Box>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle1" noWrap textOverflow="ellipsis">
              {asset.title}
            </Typography>
            {ceAppName && (
              <Stack direction="row">
                <Chip label={ceAppName} />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

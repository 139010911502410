import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { debugLabel } from "../../generate/store";
import { previewFamily } from "./preview-family";
import { DhTemplateConfig } from "./types";

export const previewUrlFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
    templateConfig: templateConfig,
    imageType,
    imageIndex,
    orientation,
    useSelectedLogo,
    useSelfDrivingLogos,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum?: string;
    templateConfig: DhTemplateConfig;
    imageType: string;
    imageIndex: number;
    orientation: Orientation;
    useSelectedLogo: boolean;
    useSelfDrivingLogos: boolean;
  }) =>
    debugLabel(
      `previewFamily(${imgNum})`,
      atomWithDefault(async (get) => {
        if (!imgNum) return null;
        const preview = await get(
          previewFamily({
            formatId,
            answers,
            imgNum,
            templateConfig,
            imageType,
            imageIndex,
            orientation,
            useSelectedLogo,
            useSelfDrivingLogos,
          }),
        );
        if (!preview) return null;
        return await new Promise<string>((resolve, reject) => {
          DSHDLib.getVariableTemplatePreviewURL(preview, (err, url) => {
            if (err) {
              reject(err);
            } else {
              resolve(url);
            }
          });
        });
      }),
    ),
  isEqual,
);

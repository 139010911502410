import { Box, Button } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCustomTemplates } from "../../pages/create-content/use-custom-templates";
import { toBase64 } from "../../utils/base64";
import { useAnalytics } from "../analytics/analytics";
import { previewsFamily } from "../multiformat/store/previews-family";
import { ContentPreviewImage } from "./content-preview-image";
import { contentTypeFamily } from "./store/content-type-family";
import { dhTemplateConfigFamily } from "./store/dh-template-config-family";

export const ContentPreviews: FC<{
  contentTypeName: string;
  answers: { answer: string }[];
  useSelectedLogo: boolean;
  useSelfDrivingLogos: boolean;
}> = ({ contentTypeName, answers, useSelectedLogo, useSelfDrivingLogos }) => {
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();
  const { t } = useTranslation();

  const contentType = useAtomValue(contentTypeFamily(contentTypeName));
  const contentTypeAppName = contentType?.["CE App Name"];
  const templateConfig = useAtomValue(dhTemplateConfigFamily(contentTypeName));
  const { data: customTemplates } = useCustomTemplates();

  const previews = useAtomValue(
    previewsFamily({
      contentType: contentTypeName,
      answers,
      customTemplates,
      useSelectedLogo,
      useSelfDrivingLogos,
    }),
  );

  const ratio = useMemo(() => {
    if (templateConfig?.dimensions?.width && templateConfig?.dimensions?.height) {
      return templateConfig.dimensions.height / templateConfig.dimensions.width;
    }
    return 0;
  }, [templateConfig]);

  const [numItems, setNumItems] = useState(4);
  const itemsPerPage = 4;
  const numPreviews = previews?.length ?? 0;

  useEffect(() => {
    if (numPreviews > 0) {
      setNumItems(Math.min(numPreviews, itemsPerPage));
    }
  }, [answers, numPreviews]);

  const loadMore = useRef<HTMLButtonElement>(null);

  const handleLoadMore = useCallback(() => {
    gaEvent("multiformat_button", { action: "click", value: "Load More" });

    setNumItems((prev) => Math.min(prev + itemsPerPage, numPreviews));
    setTimeout(() => {
      loadMore.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, [gaEvent, numPreviews]);

  const handleEdit = useCallback(
    (i: number) => {
      if (!contentTypeAppName) {
        return;
      }

      gaEvent("multiformat_button", {
        action: "click",
        value: `Edit Position ${i + 1}`,
      });

      const preview = previews?.[i].preview;

      if (preview?.state === "hasData" && preview.data) {
        const d = toBase64(
          JSON.stringify({
            contentType: contentType["Content Type"],
            customizations: preview.data.customizations,
            templateConfig: { ...templateConfig, templateCode: preview.data.template_code },
            page: preview.data.page_number,
          }),
        );

        const params = new URLSearchParams({
          d,
          title:
            preview.data.title && preview.data.title !== ""
              ? preview.data.title
              : contentTypeAppName,
        });
        void navigate({ pathname: "/create-content/edit", search: params.toString() });
      }
    },
    [contentTypeAppName, gaEvent, previews, contentType, templateConfig, navigate],
  );

  return (
    <>
      <Box
        sx={[
          (theme) => ({
            display: "grid",
            gap: 2,
            [theme.breakpoints.down("xs")]: {
              gridTemplateColumns: "1fr",
            },
            [theme.breakpoints.up("sm")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          }),
        ]}
      >
        {previews?.map((preview, i) => (
          <ContentPreviewImage
            key={i}
            image={preview.url}
            onEdit={() => handleEdit(i)}
            ratio={ratio}
            hidden={i >= numItems}
          />
        ))}
      </Box>
      {numItems < numPreviews && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Button
            data-analytics-id="design-huddle-content-previews-load-more"
            onClick={handleLoadMore}
            ref={loadMore}
            variant="contained"
            color="primary"
            size="large"
            loading={false}
          >
            {t("Load More")}
          </Button>
        </Box>
      )}
    </>
  );
};

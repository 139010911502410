import {
  $getRoot,
  $isElementNode,
  $isParagraphNode,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  ParagraphNode,
  RootNode,
  TextNode,
} from "lexical";

import {
  IMjmlButtonProps,
  IMjmlColumnProps,
  MjmlButton,
  MjmlColumn,
  MjmlDivider,
  MjmlGroup,
  MjmlSection,
  MjmlText,
  MjmlWrapper,
} from "@faire/mjml-react";
import { $isListNode, ListItemNode, ListNode } from "@lexical/list";
import {
  $isDecoratorBlockNode,
  DecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { $isHeadingNode, HeadingNode } from "@lexical/rich-text";
import {
  $isTableCellNode,
  $isTableNode,
  $isTableRowNode,
  TableCellNode,
  TableNode,
} from "@lexical/table";
import ReactDOMServer from "react-dom/server";
import { UUID } from "../../../../../utils/uuid";
import { ConditionalWrapper } from "../../../../generic/components/conditional-wrapper";
import { $isDesignHuddleNode, DesignHuddleNode } from "../../nodes/design-huddle-node";
import { $isDividerNode, DividerNode } from "../../nodes/divider-node";
import { $isImageNode, ImageNode } from "../../nodes/image-node";
import { $getPaperColorNode } from "../../nodes/paper-color-node";
import { $isStylableButtonNode, StylableButtonNode } from "../../nodes/stylable-button-node";
import { SerialisedEditorDesign } from "../../types/serialised-editor-design";
import { createConfiguredEditor } from "../headless-editor";
import { exportHtml } from "./export-html";
export type BodyClassName = "paper" | "header" | "footer";

export function convertToMjmlString(
  content: SerialisedEditorDesign,
  account_id: UUID,
  className: BodyClassName,
  paperSize?: number | null,
  notificationPreview: boolean = false,
): string {
  const editor = createConfiguredEditor({ format: "email" });

  editor.setEditorState(editor.parseEditorState(content));

  return editor
    .getEditorState()
    .read(() =>
      $convertToMjmlString(editor, account_id, className, paperSize, notificationPreview),
    );
}

export function $convertToMjmlString(
  editor: LexicalEditor,
  account_id: UUID,
  className: BodyClassName,
  paperSize?: number | null,
  notificationPreview: boolean = false,
): string {
  const paperColorNode = $getPaperColorNode();
  const extraProps = paperColorNode ? { backgroundColor: paperColorNode.__hex } : {};

  const data = ReactDOMServer.renderToString(
    <MjmlWrapper cssClass={className} mjmlClass={className} {...extraProps}>
      {notificationPreview && (
        <MjmlSection mjmlClass="section">
          <MjmlColumn>
            <MjmlText line-height="0px" padding="0px">
              {Array.from({ length: 100 }).map((_, i) => (
                <div style={{ display: "none" }} key={i}>
                  &#8199;&#65279;&#847;
                </div>
              ))}
            </MjmlText>
          </MjmlColumn>
        </MjmlSection>
      )}
      {transformRoot($getRoot(), editor, account_id, className, paperSize ?? undefined)}
    </MjmlWrapper>,
  );
  return data;
}

const transformRoot = (
  node: RootNode,
  editor: LexicalEditor,
  account_id: UUID,
  className: BodyClassName,
  paperSize?: number,
): React.JSX.Element[] => {
  // If we have any tables, they are considered to be a "row", so we need to group correctly.
  // e.g if node.getChildren() s [paragraph, paragraph, paragraph, table, paragraph]
  // we want to return [[paragraph, paragraph, paragraph], [table], [paragraph]]
  const groups = node
    .getChildren()
    .reduce<LexicalNode[][]>(
      (acc, x) => {
        if ($isTableNode(x)) {
          acc.push([x]);
          acc.push([]);
          return acc;
        }

        const lastGroup = acc[acc.length - 1];
        lastGroup.push(x);
        return acc;
      },
      [[]],
    )
    .filter((x) => x.length > 0);

  const toElements = (group: LexicalNode[]): (React.JSX.Element | React.JSX.Element[])[] =>
    group
      .filter(
        (x): x is DecoratorBlockNode | ElementNode => $isDecoratorBlockNode(x) || $isElementNode(x),
      )
      .map((x) => transfomTopLevel(x, editor, account_id, className, paperSize));

  return groups.map((group, i) => (
    <MjmlSection mjmlClass="section" key={i}>
      {$isTableNode(group[0]) ? toElements(group) : <MjmlColumn>{toElements(group)}</MjmlColumn>}
    </MjmlSection>
  ));
};

const transfomTopLevel = (
  node: LexicalNode,
  editor: LexicalEditor,
  account_id: UUID,
  className: BodyClassName,
  paperSize?: number,
  columnCount: number = 1,
): React.JSX.Element | React.JSX.Element[] => {
  if ($isParagraphNode(node) || $isHeadingNode(node)) return transformText(node, editor);

  if ($isListNode(node)) return transformList(node, editor);

  if ($isImageNode(node)) return transformImage(node, columnCount, paperSize);

  if ($isStylableButtonNode(node)) return transformButton(node);

  if ($isDividerNode(node)) return transformDividerNode(node);

  if ($isDesignHuddleNode(node)) return transformDesignHuddle(node, columnCount, paperSize);

  if ($isTableNode(node)) return transformTableNode(node, editor, account_id, className, paperSize);

  throw new Error(`Unknown node type: ${node.getType()}`);
};

const transformDividerNode = (node: DividerNode): React.JSX.Element => {
  if (!node.__divider)
    return <MjmlDivider key={node.__key} borderWidth="1px" borderColor="#777777" />;

  return <MjmlDivider key={node.__key} mjmlClass={`${node.__divider.type}_divider`} />;
};

const transformTableNode = (
  node: TableNode,
  editor: LexicalEditor,
  account_id: UUID,
  className: BodyClassName,
  paperSize?: number,
): React.JSX.Element | React.JSX.Element[] => {
  const cells = node
    .getChildren()
    .filter($isTableRowNode)
    .flatMap((x) => x.getChildren())
    .filter($isTableCellNode);
  return (
    <MjmlGroup key={node.__key}>
      {cells.map((x) =>
        transformTableCellNode(x, editor, account_id, className, cells.length, paperSize),
      )}
    </MjmlGroup>
  );
};

const transformTableCellNode = (
  node: TableCellNode,
  editor: LexicalEditor,
  account_id: UUID,
  className: BodyClassName,
  groupSize: number,
  paperSize?: number,
): React.JSX.Element => {
  const children = node
    .getChildren()
    .map((x) => transfomTopLevel(x, editor, account_id, className, paperSize, groupSize));

  const props: IMjmlColumnProps = {
    ...(node.__width ? { width: `${node.__width}px` } : {}),
    ...(node.__backgroundColor ? { backgroundColor: node.__backgroundColor } : {}),
  };

  return (
    <MjmlColumn
      mjmlClass={className !== "header" ? "column" : undefined}
      key={node.__key}
      {...props}
    >
      {children}
    </MjmlColumn>
  );
};

const transformDesignHuddle = (
  node: DesignHuddleNode,
  columnCount: number = 1,
  paperSize: number = 600,
): React.JSX.Element => {
  if (!node.__id) return <></>;

  const widthPercent = (node.__proportionOfMaxWidth ?? 1) * 100;
  const pixelWidth = (paperSize / 100) * (1 / columnCount) * widthPercent;

  const url = node.getDesignHuddlePreviewUrl() || node.getImageUrl();
  const href = node.getHref();

  return (
    <MjmlText
      key={node.__key}
      align={node.__format === "center" ? "center" : node.__format === "right" ? "right" : "left"}
    >
      <ConditionalWrapper condition={!!href} wrapper={(children) => <a href={href}>{children}</a>}>
        <img
          src={url}
          alt=""
          width={pixelWidth}
          loading="lazy"
          style={{
            width: `${pixelWidth}px`,
            maxWidth: `${widthPercent}%`,
            aspectRatio: node.__aspect,
          }}
        />
      </ConditionalWrapper>
    </MjmlText>
  );
};

const transformButton = (node: StylableButtonNode): React.JSX.Element => {
  const props: IMjmlButtonProps = {
    href: node.__url,
    align: node.__format === "center" ? "center" : node.__format === "right" ? "right" : "left",
  };

  if (node.__fullWidth) {
    return (
      <MjmlButton
        mjmlClass={node.__buttonType}
        cssClass="btn-full-width"
        width="100%"
        key={node.__key}
        {...props}
      >
        {node.__text}
      </MjmlButton>
    );
  }

  return (
    <MjmlButton mjmlClass={node.__buttonType} key={node.__key} {...props}>
      {node.__text}
    </MjmlButton>
  );
};

const transformImage = (
  node: ImageNode,
  columnCount: number = 1,
  paperSize: number = 600,
): React.JSX.Element => {
  const widthPercent = (node.__proportionOfMaxWidth ?? 1) * 100;
  const pixelWidth = (paperSize / 100) * (1 / columnCount) * widthPercent;
  const url = node.getHref();

  return (
    <MjmlText
      key={node.__key}
      align={node.__format === "center" ? "center" : node.__format === "right" ? "right" : "left"}
      padding={0}
    >
      <ConditionalWrapper condition={!!url} wrapper={(children) => <a href={url}>{children}</a>}>
        <img
          src={node.__url}
          alt=""
          width={pixelWidth}
          loading="lazy"
          style={{
            width: `${pixelWidth}px`,
            maxWidth: `${widthPercent}%`,
            aspectRatio: node.__aspect,
          }}
        />
      </ConditionalWrapper>
    </MjmlText>
  );
};

const transformText = (
  node: ParagraphNode | HeadingNode | TextNode | ListItemNode,
  editor: LexicalEditor,
): React.JSX.Element => {
  return (
    // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
    <MjmlText key={node.__key} dangerouslySetInnerHTML={{ __html: exportHtml(editor, node) }} />
  );
};

const transformList = (node: ListNode, editor: LexicalEditor): React.JSX.Element => {
  const listItems = extractListItems(node, editor);

  const props = {
    // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
    dangerouslySetInnerHTML: { __html: listItems },
  };
  return (
    <MjmlText key={node.getKey()}>
      {node.getListType() === "number" ? <ol {...props}></ol> : <ul {...props}></ul>}
    </MjmlText>
  );
};

const extractListItems = (node: ListNode, editor: LexicalEditor, nestLevel = 1): string => {
  const list = node.getChildren<ListItemNode>().map((listItemNode: ListItemNode) => {
    const childNodes = listItemNode.getChildren();
    if (childNodes.length === 1 && $isListNode(childNodes[0])) {
      const props = {
        className: `nestedlist_${nestLevel}`,
        // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
        dangerouslySetInnerHTML: { __html: extractListItems(childNodes[0], editor, nestLevel + 1) },
      };
      return ReactDOMServer.renderToString(
        node.getListType() === "number" ? <ol {...props}></ol> : <ul {...props}></ul>,
      );
    }

    return exportHtml(editor, listItemNode);
  });

  return list.join("");
};

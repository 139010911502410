import { Box, BoxProps, useTheme } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { FC, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";

export interface PageContentProps extends PropsWithChildren, BoxProps {
  fullWidth?: boolean;
  centerContent?: boolean;
}

export const usePageContentMx = (): string => {
  const theme = useTheme();
  return `min(min(${theme.spacing(10)}, 5vw), 10vw)`;
};

export const PageContent: FC<PageContentProps> = ({
  children,
  maxWidth = "xl",

  fullWidth = false,
  title,
  sx = [],
  centerContent = false,
  ...props
}) => {
  const { t } = useTranslation();

  const pageContentMx = usePageContentMx();

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return (
    <Box
      mt={2}
      mb={4}
      sx={[
        centerContent
          ? (theme) => ({
              maxWidth: "lg",
              mx: "auto",
              px: theme.spacing(4),
            })
          : (theme) => ({
              mx: fullWidth ? `${theme.spacing(10)}` : pageContentMx,
            }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      <ErrorBoundary
        fallback={
          <Box>
            {t(
              "There was an error rendering the page content. If this issue persists, please contact customer support.",
            )}
          </Box>
        }
      >
        <Box {...(!fullWidth && { maxWidth })} flexGrow={1}>
          {children}
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

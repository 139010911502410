import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { isEmpty } from "lodash-es";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Database } from "../../../server/supabase/types/database-definitions";

export const ConfirmDeleteDialog: FC<{
  event?: Database["public"]["Views"]["events_with_related_moments_calendar"]["Row"];
  onClose: () => void;
  onConfirm: () => Promise<void>;
}> = ({ event, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  const doConfirm = async (): Promise<void> => {
    setDeleting(true);
    await onConfirm();
    setDeleting(false);
  };

  return (
    <Dialog open={!isEmpty(event)} onClose={onClose}>
      <DialogTitle>
        {t("Confirm you would like to delete event: {{title}} event?", { title: event?.title })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {t(
            "Existing moments that reference this event's merge tag won't be affected and the merge tag will still be displayed.",
          )}
        </DialogContentText>
        <DialogActions>
          <Button data-analytics-id="calendar-event-table-delete-dialog-cancel" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            data-analytics-id="calendar-event-table-delete-dialog-confirm"
            loading={deleting}
            onClick={() => {
              void doConfirm().catch(console.error);
            }}
          >
            {t("Confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

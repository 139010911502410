import { Box, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router";

export const LaunchContentCard: FC<{ title: string; format: string; image: string }> = ({
  title,
  format,
  image,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    const params = new URLSearchParams({ format });

    void navigate({ pathname: "/create-content", search: params.toString() });
  }, [navigate, format]);

  return (
    <Box
      onClick={handleClick}
      sx={{
        maxWidth: "160px",
        maxHeight: "200px",
        background: "linear-gradient(to right bottom, #00B6EF, #C24599)",
        color: "white",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      <Box
        component="img"
        sx={{
          maxWidth: "160px",
        }}
        alt={title}
        src={image}
      ></Box>
      <Box
        sx={{
          maxHeight: "35px",
          paddingBottom: "4px",
        }}
      >
        <Typography variant="body2">{title}</Typography>
      </Box>
    </Box>
  );
};

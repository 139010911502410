import { Edit, Save } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem, useGridApiContext } from "@mui/x-data-grid-pro";
import { toPairs, values } from "lodash-es";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../utils/uuid";
import { ColumnValidator } from "./types";

export const SaveAction: FC<{
  id: UUID;
  onClick: () => void;
  validators: { [field: string]: ColumnValidator<unknown> | undefined };
  isNew: boolean;
}> = ({ id, onClick, validators, isNew }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const [disabled, setDisabled] = useState(false);

  const rowEditState = apiRef.current!.state.editRows[id];

  useEffect(() => {
    void (async () => {
      let isDisabled = rowEditState ? values(rowEditState).some((x) => !!x.error) : false;
      if (isNew && !isDisabled) {
        // If not disabled we check the validation again, this is because there is no way
        // of running validation on adding a new row (as far as I can tell)
        const validationPromises = toPairs(rowEditState).map((pair) => {
          const [field, value] = pair;
          return validators[field]?.(value.value);
        });

        isDisabled = (await Promise.all(validationPromises)).some((x) => !!x);
      }

      setDisabled(isDisabled);
    })().catch(console.error);
  }, [rowEditState, validators, isNew]);

  return (
    <GridActionsCellItem
      icon={
        <Tooltip title={t("Save")}>
          <Save />
        </Tooltip>
      }
      label="Save"
      onClick={onClick}
      color="primary"
      disabled={disabled}
    />
  );
};

export const EditAction: FC<{
  onClick: () => void;
  preventEditing?: boolean;
  preventEditingReason?: string;
}> = ({ onClick, preventEditing, preventEditingReason }) => {
  const { t } = useTranslation();
  const action = (
    <GridActionsCellItem
      icon={
        <Tooltip title={t("Edit")}>
          <Edit />
        </Tooltip>
      }
      label="Edit"
      className="textPrimary"
      onClick={onClick}
      color="inherit"
      disabled={preventEditing}
    />
  );

  return preventEditing && preventEditingReason ? (
    <Tooltip title={preventEditingReason}>
      <span>{action}</span>
    </Tooltip>
  ) : (
    action
  );
};

import { Close, ContentCopy, DeleteOutline, Edit, FileDownload } from "@mui/icons-material";
import {
  Alert,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../utils/hooks/use-dialog";
import { usePopover } from "../../utils/hooks/use-popover";
import { useDesignHuddleProjectClone } from "../design-huddle/use-design-huddle-project-clone";
import { ExportMenu } from "./asset-export-menu";
import { useDeleteAssetCallback } from "./hooks/use-delete-asset-callback";
import { useCreateAssetCallback } from "./hooks/use-upsert-asset";
import { Asset } from "./server/digital-asset-service";

export const AssetPreviewModal: FC<{
  open: boolean;
  onClose: () => void;
  asset: Asset | null;
  onChange?: () => void;
  onDeleted?: () => void;
}> = ({ asset, open, onClose, onChange, onDeleted }) => {
  const { t } = useTranslation();

  const [copying, setCopying] = useState(false);
  const createAsset = useCreateAssetCallback();

  const cloneProject = useDesignHuddleProjectClone();

  const handleCopy = useCallback(async () => {
    if (!asset) return;
    if (copying) return;

    setCopying(true);

    const loadingToast = toast.custom(
      <Alert severity="info" variant="outlined" sx={{ bgcolor: "white" }}>
        {t("Creating copy...")}
      </Alert>,
    );

    const projectId = await cloneProject(asset.project_id);

    try {
      if (!projectId) return;
      await createAsset({
        projectId: projectId,
        contentType: asset.content_type,
        thumbnailUrl: asset.thumbnail_url,
        pageId: asset.page_id,
        title: `Copy of ${asset?.title}`,
      });

      toast.dismiss(loadingToast);
      toast.custom(<Alert severity="success">{t(`Copy of "${asset.title}" created`)}</Alert>, {
        duration: 2000,
      });
      onChange?.();
    } catch (e) {
      console.error(e);
      toast.dismiss(loadingToast);
      toast.custom(
        <Alert severity="error">{t("Failed to create copy. Please try again later")}</Alert>,
        { duration: 3000 },
      );
    } finally {
      setCopying(false);
    }
  }, [asset, cloneProject, copying, createAsset, onChange, t]);

  const deleteConfirmation = useDialog();
  const deleteAsset = useDeleteAssetCallback();

  const handleConfirmDelete = useCallback(async () => {
    deleteConfirmation.handleClose();

    if (!asset) return;

    try {
      await deleteAsset(asset.id);
      toast.custom(<Alert severity="success">{t(`"${asset.title}" deleted`)}</Alert>, {
        duration: 2000,
      });
      onDeleted?.();
    } catch (e) {
      console.error(e);
      toast.custom(
        <Alert severity="error">{t("Failed to delete asset. Please try again later")}</Alert>,
        {
          duration: 3000,
        },
      );
    }
  }, [asset, deleteAsset, deleteConfirmation, onDeleted, t]);

  const exportMenuController = usePopover<HTMLButtonElement>();

  if (!asset) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle display="flex" justifyContent="space-between" component="div">
          <Typography variant="h6" flexGrow={1} mt={1}>
            {asset?.title}
          </Typography>
          <IconButton
            onClick={onClose}
            data-analytics-id="content-library-preview-close"
            title={t("Close")}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CardMedia src={asset?.thumbnail_url} component="img" />
        </DialogContent>
        <DialogActions sx={{ justifyItems: "right", px: 2, mb: 2 }}>
          <Button
            ref={exportMenuController.anchorRef}
            onClick={exportMenuController.handleOpen}
            startIcon={<FileDownload />}
            data-analytics-id="asset-library-preview-export"
          >
            Export
          </Button>
          <Button
            startIcon={<ContentCopy />}
            data-analytics-id="content-library-preview-duplicate"
            onClick={() => void handleCopy()}
            loading={copying}
          >
            {t("Duplicate")}
          </Button>
          <Link startIcon={<Edit />} component={Button} href={`/content-library/${asset.id}`}>
            {t("Edit")}
          </Link>

          <Button
            startIcon={<DeleteOutline />}
            data-analytics-id="content-library-preview-delete"
            onClick={deleteConfirmation.handleOpen}
            color="error"
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteConfirmation
        asset={asset}
        open={deleteConfirmation.open}
        onClose={deleteConfirmation.handleClose}
        onConfirm={() => void handleConfirmDelete()}
      />
      <ExportMenu menuController={exportMenuController} asset={asset} />
    </>
  );
};

const DeleteConfirmation: FC<{
  asset: Asset;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ asset, open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(`Delete "${asset.title}"?`)}</DialogTitle>
      <DialogContent>
        {t(
          "This will delete the file from your Content Library and you won’t be able to view or edit it again.",
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-analytics-id="asset-delete-cancel" variant="text">
          {t("Cancel")}
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          data-analytics-id="asset-delete-confirm"
          variant="contained"
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

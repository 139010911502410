import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useFlags } from "../../../server/optimizely";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { atlasV2LibraryAtom } from "../library/store";
import { Library } from "../library/types";
import { SupabaseAtlasService } from "../server/supabase-atlas-service";

/**
 * Called to initialise the Discover library. Can be called anywhere in the app
 * multiple times and will only load once.
 */
export const useDiscoverLibraryLoader = (): void => {
  const setAtlasV2Library = useSetAtom(atlasV2LibraryAtom);

  const {
    flags: { sequences },
    flagsLoaded,
  } = useFlags("sequences");

  const { data: atlasData } = useSupabaseLoader<Library, "atlas">(
    async ({ supabase }) => {
      const { data, error } = await new SupabaseAtlasService(supabase).getAtlasV2Library(sequences);

      if (error) {
        throw error;
      }
      return data;
    },
    [sequences],
    "atlas",
    {
      waiting: !flagsLoaded,
    },
  );
  useEffect(() => {
    if (atlasData) {
      setAtlasV2Library(atlasData);
    }
  }, [atlasData, setAtlasV2Library]);
};

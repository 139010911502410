import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAsset } from "../../modules/assets/hooks/use-asset";
import { useUpdateAssetCallback } from "../../modules/assets/hooks/use-upsert-asset";
import { useDesignHuddle } from "../../modules/design-huddle/use-design-huddle";
import { useDesignHuddleProjectClone } from "../../modules/design-huddle/use-design-huddle-project-clone";
import { EditContent } from "../../modules/edit-content/edit-content";
import { LoadingModal } from "../../modules/edit-content/loading-modal";
import { SaveAssetHandler } from "../../modules/edit-content/types";
import { Page } from "../../modules/layout/components/page";
import { useApiLoader } from "../../server/api/hooks/use-api-loader";
import { UUID } from "../../utils/uuid";
import { useAtomValue } from "jotai";
import { existingProjectFamily } from "../../modules/generate/store";
import { find } from "lodash-es";

const AssetPage: FC = () => {
  const { t } = useTranslation();

  const { id } = useParams() as { id: UUID };
  const { data: asset, loading: assetLoading, error: assetRetrievalError } = useAsset(id);

  const designHuddleReady =
    useDesignHuddle(asset ? [asset.project_id] : []) || asset?.project_id === "dummy";

  const [ready, setReady] = useState(false);
  const handleReady = useCallback(() => setReady(true), []);

  const cloneProject = useDesignHuddleProjectClone();

  const projectPages = useAtomValue(existingProjectFamily(asset?.project_id));
  const [pageNumber, setPageNumber] = useState<number>();

  useEffect(() => {
    if (!projectPages || !asset?.page_id) return;

    const page = find(projectPages.pages, { page_id: asset.page_id });

    if (page) {
      setPageNumber(page.page_number);
    }
  }, [asset?.page_id, projectPages]);

  const {
    data: projectId,
    error: projectCloneError,
    loading: cloning,
  } = useApiLoader(async () => {
    if (designHuddleReady) {
      const newProjectId = await cloneProject(asset?.project_id);
      if (!newProjectId) {
        throw new Error("Failed to clone project");
      }
      return newProjectId;
    }
  }, [asset?.project_id, cloneProject, designHuddleReady]);

  const updateAsset = useUpdateAssetCallback();

  const handleSaveAsset: SaveAssetHandler = useCallback(
    (opts) => {
      if (!asset) return Promise.reject(new Error("Asset not found"));

      return updateAsset({
        asset,
        projectId,
        ...opts,
      });
    },
    [asset, projectId, updateAsset],
  );

  if (!asset || !pageNumber) return null;
  if (assetRetrievalError || projectCloneError) {
    console.error(assetRetrievalError || projectCloneError);
    return (
      <div>{t("Sorry, we encountered a problem trying to load this page. Please try again.")}</div>
    );
  }

  return (
    <Page title={t(asset.title)} sx={{ height: "100%" }}>
      {projectId && (
        <EditContent
          assetId={asset.id}
          title={asset.title}
          projectId={projectId}
          page={pageNumber}
          onReady={handleReady}
          onSave={handleSaveAsset}
        />
      )}
      {asset.project_id !== "dummy" && (
        <LoadingModal open={!ready || !designHuddleReady || cloning || assetLoading} />
      )}
    </Page>
  );
};

export default AssetPage;

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { config } from "../../../../config";
import { SlackConfig } from "../../../../pages/manage/manage-channels-page";
import { useApiServiceCallback } from "../../../../server/api/client";
import { useSupabase, useSupabaseCallback } from "../../../../server/supabase/hooks";
import { SlackIcon } from "../../../../styles/icons/slack";
import { LocalStorageService } from "../../../../utils/local-storage";
import { UUID, uuidv4 } from "../../../../utils/uuid";
import {
  HasIntegration,
  IntegrationCard,
} from "../../../generic/components/cards/integration-card";
import { SlackService } from "../client";

const storageKey = "slackState";

export const SlackCard: FC<{
  slackConfigs: SlackConfig[];
  setSlackConfigs: (slackConfigs: SlackConfig[]) => void;
  slackConfigId?: UUID;
  slackConfigName?: string | null;
  multiSlack: boolean;
}> = ({ slackConfigId, slackConfigs, multiSlack, setSlackConfigs, slackConfigName }) => {
  const [hasIntegration, setHasIntegration] = useState<HasIntegration>(!!slackConfigId);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [warning, setWarning] = useState(false);

  const setSlackInfo = useApiServiceCallback(
    SlackService,
    async ({ apiService }, state: string, code: string) => {
      const { error } = await apiService.setSlackInfo(
        code,
        state,
        slackConfigs.map((c) => c.id),
      );

      if (error) {
        toast.error(t("Something went wrong"));
        return;
      }
      const localStorage = new LocalStorageService();
      localStorage.remove("slackState");
      toast.success(t("Slack successfully connected"));
    },
    [],
  );

  const updateSlackConfigs = useSupabaseCallback(
    async ({ supabase }) => {
      const { data } = await supabase.from("slack_config").select("id,name,slack_app_id");

      if (!data) return;

      setSlackConfigs(data);
    },
    [setSlackConfigs],
  );

  useSupabase(async () => {
    if (hasIntegration !== false) return;

    const state = searchParams.get("state");
    const code = searchParams.get("code");

    if (!state || !code) return;

    const localStorage = new LocalStorageService();
    setHasIntegration("loading");

    if (localStorage.get("slackState") === state) {
      await setSlackInfo(state, code);
      setSearchParams({});
    } else {
      toast.error(t("Something went wrong"));
      return;
    }

    await updateSlackConfigs();
    setHasIntegration(false);
  }, [hasIntegration, searchParams, updateSlackConfigs, setSlackInfo, setSearchParams, t]);

  const disableIntegration = useApiServiceCallback(
    SlackService,
    async ({ apiService }) => {
      if (!slackConfigId) return;

      setWarning(false);
      setHasIntegration("loading");

      const { error } = await apiService.disconnect(slackConfigId);
      if (!error) {
        toast.success(t("Slack successfully disconnected"));
        setHasIntegration(false);
        await updateSlackConfigs();
      } else {
        toast.error(t("There was an error disconnecting Slack, please contact support."));
      }
    },
    [slackConfigId],
  );

  return (
    <Box sx={{ maxWidth: "500px" }}>
      <IntegrationCard
        title={`Slack${slackConfigName ? " - " + slackConfigName : ""}`}
        subtitle={t(
          "Integrate your communications with Slack. Send direct messages to your employees via our ChangeEngine Chatbot.",
        )}
        hasIntegration={hasIntegration}
        disableIntegration={() => Promise.resolve(setWarning(true))}
        connectButton={<ConnectButton multiSlack={multiSlack} />}
        icon={<SlackIcon />}
      />
      <Dialog open={warning}>
        <DialogTitle>
          <Alert severity="warning">
            <Trans>Moments will be lost</Trans>
          </Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              If you remove the ChangeEngine Bot from Slack then your employees will no longer be
              able to access old moments or receive future moments.
            </Trans>
          </DialogContentText>
          <DialogActions>
            <Button
              data-analytics-id="slack-configuration-card-warning-dialog-cancel"
              onClick={() => setWarning(false)}
            >
              <Trans>Continue using Slack</Trans>
            </Button>
            <Button
              data-analytics-id="slack-config-card-warning-dialog-confirm"
              color="warning"
              onClick={() => {
                void disableIntegration().catch(console.error);
              }}
            >
              <Trans>Remove old Moments from Slack</Trans>
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const ConnectButton: FC<{ multiSlack: boolean }> = ({ multiSlack }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onClick = useApiServiceCallback(
    SlackService,
    async ({ apiService }) => {
      setLoading(true);

      const state = uuidv4();
      const { data, error } = await apiService.build_app(multiSlack, state);
      if (!data || error) {
        toast.error("Something went wrong");
        setLoading(false);
        return;
      }

      const localStorage = new LocalStorageService();

      const params = new URLSearchParams({
        scope: config.slack.bot_scopes_v2.join(","),
        user_scope: config.slack.user_scopes_v2.join(","),
        redirect_uri: `${window.location.origin}/channels/slack`,
        client_id: data.clientId,
        state,
      });
      const url = `https://slack.com/oauth/v2/authorize?${params.toString()}`;
      localStorage.set(storageKey, state);
      window.location.href = url;
      setLoading(false);
    },
    [],
  );

  return (
    <Button
      data-analytics-id="slack-configuration-card-connect"
      startIcon={<SlackIcon />}
      variant="outlined"
      color={"success"}
      onClick={() => void onClick().catch(console.error)}
      loading={loading}
    >
      {t("Connect")}
    </Button>
  );
};

import { Box, Grid2, Typography } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useBrandTones } from "../../modules/brand-kit/hooks/use-brand-tones";
import { ContentTone } from "../../modules/brand-kit/server/brand-kit-service";
import { BrandingBar } from "../../modules/branding-bar/branding-bar";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { libraryQueryAtom, libraryTemplateAtom } from "../../modules/discover/library/store";
import { brandToneAtom, channelsAtom } from "../../modules/generate/store";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";

import { useResetBrandingImageType } from "../../modules/brand-kit/hooks/use-reset-branding-image-type";
import { ChannelName } from "../../modules/channels/types";
import { useGetDesignHuddleAccessToken } from "../../modules/design-huddle/use-design-huddle-access-token";
import { useEnabledChannelsLoader } from "../../modules/discover/hooks/use-enabled-channels-loader";
import { SequenceHeader } from "../../modules/layout/components/sequence-header";
import { SequencePageWrapper } from "../../modules/layout/components/sequence-page-wrapper";
import { SequenceWrapper } from "../../modules/layout/components/sequence-wrapper";
import {
  sequenceAtom,
  sequenceTemplateAtom,
  sequenceTemplateBrandingFamily,
  sequenceTemplateChannelFamily,
} from "../../modules/sequence/store";
import { TemplatePreview } from "../../modules/template-preview/template-preview";
import { UUID } from "../../utils/uuid";

const DiscoverSequencePage: FC = () => {
  const navigate = useNavigate();

  useResetBrandingImageType();
  useEnabledChannelsLoader();
  useDiscoverLibraryLoader();
  useGetDesignHuddleAccessToken();

  const { sequence_slug, template_slug } = useParams<{
    sequence_slug: string;
    template_slug: string;
  }>();

  const [templateQuery, setTemplateQuery] = useAtom(libraryQueryAtom);
  const { data: brandTones } = useBrandTones();
  const setBrandTone = useSetAtom(brandToneAtom);

  const [branding, setBranding] = useAtom(sequenceTemplateBrandingFamily(template_slug));
  const setChannel = useSetAtom(sequenceTemplateChannelFamily(template_slug));
  const sequence = useAtomValue(sequenceAtom);
  const template = useAtomValue(libraryTemplateAtom);
  const channels = useAtomValue(channelsAtom);

  useEffect(() => {
    if (sequence_slug) setTemplateQuery({ template_slug, sequence_slug });
  }, [template_slug, setTemplateQuery, sequence_slug]);

  useEffect(() => {
    const tone = brandTones?.find((b) => b.is_default);
    tone && setBrandTone(tone);

    if (!branding?.brandTone && tone) setBranding((x) => x && { ...x, brandTone: tone });
  }, [brandTones, branding?.brandTone, setBrandTone, setBranding, channels]);

  useEffect(() => {
    if (sequence && !template_slug) {
      void navigate(`/discover/sequence/${sequence_slug}/${sequence?.templates[0].slug}`, {
        replace: true,
      });
    }
  }, [navigate, sequence_slug, template_slug, template, sequence]);

  const handleContentToneChange = useCallback(
    (value: ContentTone | "custom"): void => setBranding((x) => ({ ...x, tone: value })),
    [setBranding],
  );

  const handleBrandToneChange = useCallback(
    (value: UUID): void =>
      setBranding((b) => ({ ...b, brandTone: brandTones?.find(({ id }) => id === value) })),
    [setBranding, brandTones],
  );

  const handleImageSelect = useCallback(
    (num: number): void => setBranding((x) => ({ ...x, image: num })),
    [setBranding],
  );

  const handleChannelSelect = useCallback(
    (channelName: ChannelName) => {
      const channel = channels.find((x) => x.channel_name === channelName);
      channel && setChannel(channel);
    },
    [channels, setChannel],
  );

  if (!templateQuery?.template_slug) return null;

  return (
    <SequenceWrapper>
      <SequenceHeader>
        <Typography variant="h4" sx={{ fontSize: "1.4rem", fontWeight: 500, pb: 2 }}>
          {sequence?.title}
        </Typography>
        <BrandingBar compact p={1} />
      </SequenceHeader>
      <SequencePageWrapper sidebar={{ type: "discover" }}>
        <Page title={template?.title ?? null}>
          <PageContent py={2} sx={{ mx: 4 }}>
            <Box display="flex" alignContent={"center"} bgcolor="background.paper">
              <Box sx={{ fontSize: "1.2rem", fontWeight: 500 }} flexGrow={1} role="heading">
                {template?.title}
              </Box>
            </Box>
          </PageContent>

          <Grid2 container>
            <Grid2 size={{ sm: 12 }}>
              <PageContent centerContent={true} pt={0}>
                <TemplatePreview
                  store="discover"
                  loadableTemplateAtom={sequenceTemplateAtom}
                  onContentToneChange={handleContentToneChange}
                  onBrandToneChange={handleBrandToneChange}
                  onChannelChange={handleChannelSelect}
                  onImageSelect={handleImageSelect}
                  contentTone={branding ? branding.tone : "professional"}
                  brandTone={branding?.brandTone?.id}
                  designHuddle
                />
              </PageContent>
            </Grid2>
          </Grid2>
        </Page>
      </SequencePageWrapper>
    </SequenceWrapper>
  );
};

export default DiscoverSequencePage;

import { Drawer, DrawerProps, IconButton, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRight } from "../../../../styles/icons/chevron-right";
import { Logo } from "../../../generic/components/logo";
import { Scrollbar } from "../../../generic/components/scrollbar";
import { useShowSidebar } from "../../hooks/use-show-sidebar";
import { layoutAtom } from "../store";
import { ThemeAwareDivider } from "./divider";
import { Navigation, NavigationProps } from "./navigation";
import { sidebarOpenAtom, toggleSidebarAtom } from "./store";

export const sidebarWidth = 280;

export const Sidebar: FC<
  PropsWithChildren & { footer?: ReactElement | null } & Pick<DrawerProps, "variant"> &
    NavigationProps
> = ({ children, footer = <Branding />, variant = "permanent" }) => {
  const { t } = useTranslation();
  const open = useAtomValue(sidebarOpenAtom);
  const toggle = useSetAtom(toggleSidebarAtom);

  const theme = useTheme();
  const showSidebar = useShowSidebar();

  const layout = useAtomValue(layoutAtom);

  const extraProps: DrawerProps = showSidebar
    ? {
        variant,
      }
    : {
        variant: "temporary",
        sx: { zIndex: (x: Theme) => x.zIndex.appBar + 100 },
      };

  return (
    <>
      {!showSidebar && layout === "default" && (
        <IconButton
          data-analytics-id="sidebar-open"
          onClick={toggle}
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none",
              position: "absolute",
              top: 0,
            },
          }}
          title={t("Toggle Navigation")}
        >
          <ChevronRight fontSize="large" />
        </IconButton>
      )}
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
            backgroundColor: "var(--nav-bg)",
            borderRightColor: "var(--nav-border-color)",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            color: "var(--nav-color)",
            width: sidebarWidth,
          },
        }}
        open={open}
        onClose={toggle}
        {...extraProps}
      >
        <Scrollbar
          sx={{
            height: "calc(100vh - 130px)",
            minHeight: "5%",
            "& .simplebar-content": {
              height: "87%",
            },
            "& .simplebar-scrollbar:before": {
              background: "var(--nav-scrollbar-color)",
            },
          }}
        >
          {children || (
            <Navigation {...(extraProps.variant === "temporary" && { onClose: toggle })} />
          )}
        </Scrollbar>
        {footer}
      </Drawer>
    </>
  );
};

const Branding: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
        px: 5,
        py: 1,
        height: "130px",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: "var(--nav-bg)",
        ":before": {
          content: "''",
          position: "absolute",
          top: -30,
          left: 0,
          width: "100%",
          height: 30,
          backgroundImage: "linear-gradient(to top, var(--nav-bg), transparent)",
        },
      }}
    >
      <Typography variant="caption" sx={{ color: "text.sidebar" }}>
        {t("Powered by")}
      </Typography>
      <Logo />
      <ThemeAwareDivider />
    </Stack>
  );
};

import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";

export type SerializedEmojiNode = Spread<
  {
    emojiId: string;
  },
  SerializedTextNode
>;

export class EmojiNode extends TextNode {
  __emojiId: string;

  static getType(): string {
    return "emoji";
  }

  static clone(node: EmojiNode): EmojiNode {
    return new this(node.__emojiId, node.__text, node.__key);
  }

  constructor(id: string, text: string, key?: NodeKey) {
    super(text, key);
    this.__emojiId = id;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement("span");
    const inner = super.createDOM(config);
    dom.setAttribute("emoji-id", this.__emojiId);
    inner.className = "emoji-inner";
    dom.appendChild(inner);
    return dom;
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    const inner = dom.firstChild;
    if (inner === null) {
      return true;
    }
    super.updateDOM(prevNode, inner as HTMLElement, config);
    return false;
  }

  static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
    const node = new this(serializedNode.emojiId, serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedEmojiNode {
    return {
      ...super.exportJSON(),
      emojiId: this.getEmojiId(),
      type: "emoji",
    };
  }

  getEmojiId(): string {
    const self = this.getLatest();
    return self.__emojiId;
  }
}

export function $isEmojiNode(node: LexicalNode | null | undefined): node is EmojiNode {
  return node instanceof EmojiNode;
}

export function $createEmojiNode(emojiId: string, emojiText: string): EmojiNode {
  const node = new EmojiNode(emojiId, emojiText).setMode("token");
  return $applyNodeReplacement(node);
}

import { Atom, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { isEqual } from "lodash-es";
import { debugLabel } from "../../generate/store";
import {
  Library,
  LibraryQuery,
  LibraryTemplate,
  isDiscoverTemplateQuery,
  isSequenceTemplateQuery,
} from "./types";

// Null = not attempted; False = failed to load
export const atlasV2LibraryAtom = atom<Library | false | null>(null);

export const libraryAtom = debugLabel(
  `libraryAtom`,
  atom((get) => {
    const library = get(atlasV2LibraryAtom);
    if (library === null || !library) return { programs: [], sequences: [] };
    return library;
  }),
);

export const libraryTemplatesAtom = atom((get) => {
  const { programs } = get(libraryAtom);
  return programs.flatMap((p) => p.templates);
});

export const libraryQueryAtom = atom<LibraryQuery | null>(null);

/**
 * the current library template (as per libraryQueryAtom), hydrated
 */
export const libraryTemplateAtom = debugLabel(
  `libraryTemplateAtom`,
  atom<LibraryTemplate | null>((get) => {
    const templateQuery = get(libraryQueryAtom);
    return templateQuery === null ? null : get(libraryTemplateFamily(templateQuery));
  }),
);

/**
 * a specific library template, hydrated
 */

export const libraryTemplateFamily = atomFamily<LibraryQuery, Atom<LibraryTemplate | null>>(
  (libraryQuery: LibraryQuery) => {
    return debugLabel(
      `libraryTemplateFamily/${JSON.stringify(libraryQuery)}`,
      atom((get) => {
        const { programs, sequences } = get(libraryAtom);

        if (isSequenceTemplateQuery(libraryQuery)) {
          return (
            sequences
              .find((t) => t.slug === libraryQuery.sequence_slug)
              ?.templates.find((t) => t.slug === libraryQuery.template_slug) ?? null
          );
        }

        if (isDiscoverTemplateQuery(libraryQuery)) {
          return (
            programs
              .find((p) => p.slug === libraryQuery.program_slug)
              ?.templates.find((t) => t.slug === libraryQuery.template_slug) ?? null
          );
        }

        return null;
      }),
    );
  },
  isEqual,
);

import { yupResolver } from "@hookform/resolvers/yup";
import { AutoFixHigh, Close, InfoOutlined } from "@mui/icons-material";
import { Box, BoxProps, Button, Grid2, IconButton, Stack, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useSearchParams } from "react-router";
import ThemeProvider from "../../styles/theme/theme-provider";
import { useDialog } from "../../utils/hooks/use-dialog";
import yup from "../../utils/yup";
import { usePendo } from "../analytics/hooks/use-pendo-watch";
import { CreateContentRequest, isLongform } from "../multiformat/store/types";
import { ContentDescriptionTextField } from "./content-description-textfield";
import { ContentToneSelect } from "./content-tone-select";
import { ContentTypeSelect } from "./content-type-select";
import { useBrandToneOptions } from "./hooks/use-brand-tone-options";
import { InspireMeButton } from "./inspire-me-button";
import { LongformDialog } from "./longform-prompt-dialog";

export const CreateContentCard: FC<{ onClose?: () => void } & BoxProps> = ({
  onClose,
  sx = [],
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const brandToneOptions = useBrandToneOptions();
  const pendo = usePendo();

  const schema = useMemo(() => {
    return yup.object({
      q: yup.string().required(t("Please describe the content you wish to create.")),
      tone: yup
        .string()
        .oneOf(brandToneOptions.data?.map((option) => option.value) ?? [])
        .optional(),
      format: yup.string().optional()
    });
  }, [brandToneOptions, t]);

  const methods = useForm({
    defaultValues: {
      q: searchParams.get("q") ?? searchParams.get("answer") ?? "",
      format: searchParams.get("format") ?? "Communication",
    },
    resolver: yupResolver(schema),
  });

  const format = methods.watch("format");
  const q = methods.watch("q");

  const longformDialog = useDialog();

  const handleSubmit = useCallback(
    (request: CreateContentRequest) => {
      if (format === "Communication") {
        pendo?.track("create-content-prompt", {
          format,
          tone: request.tone ?? "",
          prompt: request.q,
        });
        const params = createSearchParams({ q: request.q, brand_tone: request.tone ?? "" });
        void navigate({ pathname: "/generate-moment", search: params.toString() });
        return;
      }

      if (isLongform(format) && request.q.length < 500) {
        pendo?.track("create-content-prompt-too-short", { format, prompt: request.q });
        longformDialog.handleOpen();
        return;
      }

      pendo?.track("create-content-prompt", { format: format ?? null, prompt: request.q });
      const params = createSearchParams({ answer: request.q, format: request.format ?? "" });
      void navigate({ pathname: "/create-content/preview", search: params.toString() });

      onClose?.();
    },
    [format, navigate, onClose, longformDialog, pendo],
  );

  const handleInspire = useCallback(
    (prompt: string) => {
      methods.setValue("q", prompt);
    },
    [methods],
  );

  const handleSkip = useCallback(() => {
    const params = createSearchParams({ answer: q, format: format ?? "" });
    void navigate({ pathname: "/create-content/preview", search: params.toString() });
    longformDialog.handleClose();
  }, [q, format, navigate, longformDialog]);

  const handleContinue = useCallback(
    (prompt: string) => {
      const params = createSearchParams({ answer: prompt, format: format ?? "" });
      void navigate({ pathname: "/create-content/preview", search: params.toString() });
      longformDialog.handleClose();
    },
    [format, navigate, longformDialog],
  );

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Box
          sx={[
            (theme) => ({
              backgroundColor: "neutral.900",
              backgroundPosition: "right bottom, left top, right 40px top 40px",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                'url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/b755211d-9387-4973-832c-3aa1e3c3a414"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/037dff25-8e4d-40e0-be06-641ff8a4569c"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/a5807452-5f9f-4b4e-9b19-f37e0d4d8c62")',
              p: 5,

              [theme.breakpoints.down("md")]: {
                pr: 3,
                pb: 3,
                backgroundImage:
                  'url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/b755211d-9387-4973-832c-3aa1e3c3a414"), \
                url("https://images.changeengine.com/2286f56c-57aa-4313-bc88-fde38caaa844/037dff25-8e4d-40e0-be06-641ff8a4569c")',
              },
            }),
            ...(sx instanceof Array ? sx : [sx]),
          ]}
          {...props}
        >
          <ThemeProvider mode="dark">
            {onClose && (
              <IconButton
                data-analytics-id="create-content-close"
                onClick={onClose}
                sx={(theme) => ({
                  position: "relative",
                  float: "right",
                  top: theme.spacing(-3),
                  right: theme.spacing(-1),
                  [theme.breakpoints.down("md")]: {
                    right: theme.spacing(2),
                  },
                })}
              >
                <Close />
              </IconButton>
            )}
            <Typography variant="h5" color="text.primary">
              {t("Create Content Instantly with AI!")}
            </Typography>
            <Typography variant="body2" mt={1} color="text.primary">
              {t("Let our powerful AI generate complete content for you in seconds.")}
            </Typography>
            <Grid2 container spacing={4} my={2}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Stack gap={2}>
                  <Typography variant="caption" mt={1} color="text.primary" mb={1}>
                    {t("Style")}
                  </Typography>
                  <ContentTypeSelect />
                  {format === "Communication" && <ContentToneSelect />}
                </Stack>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Stack gap={2}>
                  <Stack gap={1} direction="row">
                    <Typography variant="caption" mt={1} color="text.primary">
                      {t("Description")}
                    </Typography>
                    <InspireMeButton onInspire={handleInspire} format={format ?? ""} />
                  </Stack>
                  <ContentDescriptionTextField />
                  {isLongform(format) && (
                    <Stack
                      display="flex"
                      alignItems="center"
                      direction="row"
                      gap={1}
                      sx={{ mt: 1 }}
                    >
                      <InfoOutlined fontSize="small" color="action" />
                      <Typography variant="body2" color="text.primary">
                        {t("Consider entering around 1,000 characters for the best results")}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid2>
            </Grid2>
          </ThemeProvider>
          <Stack direction="row" justifyContent="center">
            <Button
              data-analytics-id="create-content-generate"
              type="submit"
              variant="contained"
              size="large"
              sx={(theme) => ({
                mt: 2,
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              })}
              startIcon={<AutoFixHigh />}
            >
              {t("Generate Content")}
            </Button>
          </Stack>
        </Box>
        {longformDialog.open && (
          <LongformDialog
            open={longformDialog.open}
            contentType={format ?? ""}
            prompt={q}
            onSkip={handleSkip}
            onContinue={handleContinue}
            onClose={longformDialog.handleClose}
          />
        )}
      </form>
    </FormProvider>
  );
};

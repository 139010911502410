import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Trans } from "react-i18next";
import { LaunchContentCard } from "./launch-content-card";

export const EmptyState: FC = () => {
  return (
    <Stack gap={4} textAlign="center" maxWidth="md" mx="auto" alignItems="center" my={4}>
      <Stack gap={2} direction="row" alignItems="center">
        <svg xmlns="http://www.w3.org/2000/svg" width="50px" viewBox="0 0 24 24">
          <defs>
            <linearGradient id="Gradient1">
              <stop className="stop1" offset="8.45%" />
              <stop className="stop2" offset="106.53%" />
            </linearGradient>
          </defs>
          <style>
            {`
            .stop1 {
                stop-color: #00B6EF;
            }
            .stop2 {
                stop-color: #DF1C8E;
            }
            `}
          </style>
          <path
            d="M9 13h10l-3.45-4.5l-2.3 3l-1.55-2zm-6 8q-.825 0-1.412-.587T1 19V6h2v13h17v2zm4-4q-.825 0-1.412-.587T5 15V4q0-.825.588-1.412T7 2h5l2 2h7q.825 0 1.413.588T23 6v9q0 .825-.587 1.413T21 17zm0-2h14V6h-7.825l-2-2H7zm0 0V4z"
            fill="url(#Gradient1)"
          />
        </svg>
        <Trans>
          <Typography variant="h6">You haven&apos;t saved any content yet</Typography>
        </Trans>
      </Stack>
      <Trans>
        <Typography variant="body1">
          When you create content with ChangeEngine, you&apos;ll be able to save it here to reuse at
          any time. Use the &quot;Save&quot; button on the edit screen.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          (Excludes Moments, which you can find on the home page or in &quot;Sent Moments&quot;)
        </Typography>
        <Typography variant="h6">Create New Content</Typography>

        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            <LaunchContentCard
              title="Manager One-Pager"
              image="https://images.changeengine.com/onepager.png"
              format="Manager One-Pager"
            />
            <LaunchContentCard
              title="Employee FAQ"
              image="https://images.changeengine.com/faq.png"
              format="Employee FAQ"
            />
            <LaunchContentCard
              title="Square Logo"
              image="https://images.changeengine.com/squarelogo.png"
              format="Logo Square"
            />
            <LaunchContentCard
              title="Landscape Logo"
              image="https://images.changeengine.com/landscapelogo.png"
              format="Logo Landscape"
            />
            <LaunchContentCard
              title="Zoom Background"
              image="https://images.changeengine.com/zoom.png"
              format="Zoom Background"
            />
          </Stack>
          <Stack direction="row" gap={2} justifyContent="center">
            <LaunchContentCard
              title="TV Display"
              image="https://images.changeengine.com/tv.png"
              format="TV Display"
            />
            <LaunchContentCard
              title="Poster"
              image="https://images.changeengine.com/poster.png"
              format="Poster"
            />
            <LaunchContentCard
              title="Certificate"
              image="https://images.changeengine.com/certificate.png"
              format="Certificate"
            />
            <LaunchContentCard
              title="Horizontal Banner"
              image="https://images.changeengine.com/horiztonalbanner.png"
              format="Header Graphic"
            />
          </Stack>
        </Stack>
      </Trans>
    </Stack>
  );
};

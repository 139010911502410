/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  SupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type PersonWithManager = Database["public"]["Views"]["person_csv_data"]["Row"];
export type Permissions = Database["public"]["Views"]["permissions"]["Row"];
export type Person = Database["public"]["Tables"]["person"]["Row"];
export type PersonTextDataJson = Database["public"]["Views"]["person_text_data_json"]["Row"];

export class SupabasePersonService extends SupabaseService<"person"> {
  table = "person" as const;

  async getAllWithManager(account_id: UUID) {
    const select = this.client.from("person_csv_data").select("*");
    return this.getAllFromSelection(account_id, select, { order: [{ column: "id" }] });
  }

  async getWithManagerById(id: UUID) {
    return await this.client.from("person_csv_data").select("*").eq("id", id).single();
  }

  async getUniqueValues(account_id: UUID, key: string) {
    const select = this.client.from("unique_person_text_values").select("*").eq("key", key);
    return await this.getAllFromSelection(account_id, select, { order: [{ column: "key" }] });
  }
  
  async getUniqueValuesByAudience(account_id: UUID, key: string, audience: string) {
    const select = this.client
      .from("unique_recipient_person_text_values")
      .select("*")
      .eq("audience", audience)
      .eq("key", key);
    return await this.getAllFromSelection(account_id, select, { order: [{ column: "key" }] });
  }
  async getUniqueValuesForCountryType(account_id: UUID, key: string) {
    const select = this.client
      .from(`unique_person_country_values`)
      .select("*")
      .or(`key.eq.${key},key.is.null`);
    return await this.getAllFromSelection(account_id, select, { order: [{ column: "key" }] });
  }
}

export class SupabasePermissionsService extends SupabaseServiceView<"permissions"> {
  table = "permissions" as const;

  async setSuperAdmins(person_ids: UUID[]) {
    return this.client.rpc("set_super_admins", { person_ids });
  }

  async setPermissions(person_ids: UUID[], permissions: { [k: string]: UUID[] }) {
    return this.client.rpc("set_permissions", {
      person_ids,
      permissions_: permissions,
    });
  }

  async removePermissions(person_id: UUID) {
    return this.client.rpc("remove_all_permissions", { person_id });
  }

  async getCurrentUserPermissions() {
    return this.client.rpc("current_user_permissions");
  }
}

export class SupabasePersonTextDataJsonService extends SupabaseServiceView<"person_text_data_json"> {
  table = "person_text_data_json" as const;
}

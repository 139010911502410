import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
  Stack,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseLoader } from "../../../../server/supabase/hooks";
import { uuidv4 } from "../../../../utils/uuid";
import { CeTextInput } from "../../../generic/components/form-fields/ce-text-field";
import { SupabaseAccountService } from "../../../generic/server/supabase-account-service";
import {
  personalEmailConfig,
  slackConfig,
  teamsConfig,
  twilioConfig,
  workEmailConfig,
} from "../../../moment/component/config";
import { MomentFormMoment } from "../../../moment/types/moment-form-moment";
import { UsableAccountPersonField } from "../../server/supabase-segment-field-service";
import { Segment, makeDefaultSegment } from "../../types";
import { segmentFormSchema } from "../../types/segment-form-schema";
import { BuildSegmentFilters } from "../build-segment-filters";
import { SegmentOperation } from "../segment-operation/operation";
import { SegmentManagerRecipientCount } from "./segment-manager-recipient-count";

export type SegmentFormSaveErrors = {
  "segment.name"?: string;
};

export const SegmentModalForm: FC<{
  fields: UsableAccountPersonField[];
  onSave: (segment: Segment | null) => Promise<SegmentFormSaveErrors | undefined>;
  onCancel: () => void;
  defaultSegmentManager?: boolean;
  disabled?: boolean;
  initialValues?: Pick<MomentFormMoment, "segment"> & {segment: {name: string}};
  isRecipientType: boolean;
}> = ({
  fields,
  onSave,
  onCancel,
  defaultSegmentManager = false,
  disabled = false,
  initialValues,
  isRecipientType,
}) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const methods = useForm({
    defaultValues: initialValues ?? {
      segment: { ...makeDefaultSegment(uuidv4()), is_recipient_type: isRecipientType },
    },
    resolver: defaultSegmentManager ? undefined : yupResolver(segmentFormSchema),
    context: {},
  });

  const { loading, data: channels } = useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data } = await new SupabaseAccountService(supabase).get(account_id);
    return [
      ...(data && data.enable_slack ? [slackConfig] : []),
      workEmailConfig,
      personalEmailConfig,
      ...(data && data.enable_teams ? [teamsConfig] : []),
      ...(data && data.enable_twilio ? [twilioConfig] : []),
    ];
  }, []);

  const { setError } = methods;

  const handleSave = useCallback(
    async (segment: {segment: Partial<MomentFormMoment["segment"]>}): Promise<void> => {
      setSaving(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // TODO: Really should fix this; the is no guarantee this is a fully valid segment
      const errs = await onSave(segment.segment);
      if (errs) {
        Object.entries(errs).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError(key, {
            type: "manual",
            message: value,
          });
        });
      }
      setSaving(false);
    },
    [onSave, setError],
  );

  if (loading || !channels) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <DialogTitle>
        {defaultSegmentManager ? t("Set default segment filters") : t("Segment Builder")}
      </DialogTitle>
      <DialogContent>
        {defaultSegmentManager && (
          <DialogContentText>
            <Trans>
              When creating a new Segment, you may want some filters pre-populated by default. You
              can set these up below. Once saved, whenever a user creates a &quot;New Segment&quot;
              anywhere in ChangeEngine, their Segment will have these filters preloaded.
            </Trans>
          </DialogContentText>
        )}
        <Stack spacing={2}>
          {!defaultSegmentManager && (
            <>
              <CeTextInput<MomentFormMoment>
                name="segment.name"
                variant="standard"
                label={t("Name")}
                required={true}
              />
              <CeTextInput<MomentFormMoment>
                name="segment.description"
                variant="standard"
                label={t("Description")}
              />
            </>
          )}
          <Stack sx={{ pt: 2, pb: 1 }}>
            <SegmentOperation
              disabled={disabled}
              fields={fields}
              disableAudienceClearable={!defaultSegmentManager && !isRecipientType}
            />
          </Stack>
          <Card variant="outlined" sx={{ p: 2 }}>
            <Stack spacing={2}>
              <BuildSegmentFilters
                disabled={disabled}
                showValidationErrors={true}
                segmentFields={fields}
              />
              <Grid2 container justifyContent="flex-start">
                <SegmentManagerRecipientCount channels={channels} />
              </Grid2>
            </Stack>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id="segment-modal-form-cancel"
          onClick={onCancel}
          disabled={saving}
          color="warning"
        >
          {t("Cancel")}
        </Button>
        <Button
          data-analytics-id="segment-modal-form-save"
          variant="contained"
          type="submit"
          loading={saving}
          onClick={() => {
            void methods
              .handleSubmit((x) => handleSave(x))()
              .catch(console.error);
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </FormProvider>
  );
};

import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { addClassNamesToElement } from "@lexical/utils";
import { DOMExportOutput, ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical";
import { EmailButtonType } from "../../../email-design/types/email-button-type";
import { ButtonNodeBlock } from "../types/markdown-node-blocks";
import { StylableButtonForm } from "../types/styleable-button";

export const positionOptions = ["left", "center", "right"] as const;
export type Position = (typeof positionOptions)[number];

export type SerializedStylableButtonNode = Spread<
  Omit<ButtonNodeBlock, "id"> & {
    buttonType: EmailButtonType;
    fullWidth: boolean;
    type: "styleablebutton";
    version: number;
  },
  SerializedDecoratorBlockNode
>;

export class StylableButtonNode extends DecoratorBlockNode {
  __text: string;
  __url: string;
  __buttonType: EmailButtonType;
  __fullWidth: boolean;

  constructor(
    text: string,
    url: string,
    buttonType: EmailButtonType,
    fullWidth: boolean,
    format?: ElementFormatType,
    key?: NodeKey,
  ) {
    super(format, key);
    this.__text = text;
    this.__url = url;
    this.__buttonType = buttonType;
    this.__fullWidth = fullWidth;
  }

  static getType(): string {
    return "styleablebutton";
  }

  static importJSON(serializedNode: SerializedStylableButtonNode): StylableButtonNode {
    return new this(
      serializedNode.text,
      serializedNode.url,
      serializedNode.buttonType,
      serializedNode.fullWidth,
      serializedNode.format,
    );
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("span");
    addClassNamesToElement(dom, "decorator-block-node");
    return dom;
  }

  static clone(node: StylableButtonNode): StylableButtonNode {
    return new this(
      node.__text,
      node.__url,
      node.__buttonType,
      node.__fullWidth,
      node.__format,
      node.__key,
    );
  }

  exportDOM(): DOMExportOutput {
    const dom = document.createElement("div");
    dom.setAttribute("data-node-type", "stylable-button-node");
    const span = document.createElement("span");
    const buttonEl = document.createElement("a");

    if (this.__fullWidth) {
      dom.style.display = "block";
      dom.style.width = "100%";
    }

    buttonEl.style.appearance = "button";
    buttonEl.style.textDecoration = "none";
    buttonEl.setAttribute("data-button-format", this.__format);

    addClassNamesToElement(buttonEl, this.__buttonType);

    buttonEl.href = this.__url;
    buttonEl.textContent = this.__text;

    span.append(buttonEl);
    dom.append(span);

    return { element: dom };
  }

  exportJSON(): SerializedStylableButtonNode {
    return {
      type: "styleablebutton",
      text: this.__text,
      url: this.__url,
      buttonType: this.__buttonType,
      fullWidth: this.__fullWidth,
      version: 1,
      format: this.__format,
    };
  }

  setButtons(data: StylableButtonForm): void {
    const writable = this.getWritable();
    writable.__text = data.text;
    writable.__url = data.url;
    writable.__buttonType = data.buttonType;
    writable.__format = data.format;
    writable.__fullWidth = data.fullWidth;
  }

  decorate(): React.JSX.Element {
    return <></>;
  }
}

export function $isStylableButtonNode(
  node: LexicalNode | null | undefined,
): node is StylableButtonNode {
  return node instanceof StylableButtonNode;
}

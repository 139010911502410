import { Loadable } from "jotai/vanilla/utils/loadable";
import { Orientation } from "unsplash-js";
import { paths } from "../../../../api-engine/definitions/self-driving-definitions";
import { ElementClassProperties } from "../../design-huddle/brand-info-customizations/types";

export type CreateContentRequest = {
  q: string;
  format?: string;
  tone?: string;
};
export type Multiformat =
  paths["/multiformat"]["get"]["responses"]["200"]["content"]["application/json"];

export type ContentType = Multiformat["content-types"][number];

export type Layout = Multiformat["breakdown"][string][number];

export type DhTemplateConfig = {
  templateCode: string;
  dimensions: { width?: number; height?: number };
};
export type ContentTypeOption = {
  label: string;
  value: string;
};

export const isLongform = (format: string | undefined): format is "Employee FAQ"|"Manager One-Pager" =>
  ["Employee FAQ", "Manager One-Pager"].includes(format!);

export type PreviewInfo = {
  width?: number;
  height?: number;
  template_code: string;
  customizations: {
    [key: string]: unknown;
  };
  page_number: number;
  title?: string;
};

export type PreviewInfoWithPhoto = PreviewInfo & {
  customizations: {
    classes: {
      Photo: ElementClassProperties;
      [key: string]: ElementClassProperties;
    };
  };
};

export type UnsplashPreviewInfo = PreviewInfoWithPhoto & {
  imageType: "Photo";
};

export type IconFinderPreviewInfo = PreviewInfoWithPhoto & {
  imageType: "Icon";
};

export type TemplatePreview = {
  preview: Loadable<Promise<PreviewInfo | null>>;
  url: Loadable<Promise<string | null>>;
  formatId: string;
  imageType: string | undefined;
  orientation: Orientation;
};

import { Database } from "../../../server/supabase/types/database-definitions";
import { ISO8601 } from "../../../utils/iso8601";
import { UUID, uuidv4 } from "../../../utils/uuid";
import yup, { yupUUID } from "../../../utils/yup";

export const segmentConditionSchema = yup
  .object({
    id: yupUUID.required(),
    field: yup.string().nullable().required("Select a field for the filter"),
    operation: yup
      .mixed<Database["public"]["Tables"]["segment_condition"]["Row"]["operation"]>()
      .oneOf(["IS", "BEFORE", "AFTER", "ON", "IS EMPTY"])
      .required("Select an operator for the filter"),
    values: yup
      .array(yup.string().required())
      .when("operation", {
        is: (val: string) => val !== "IS EMPTY",
        then: (schema) => schema.min(1, "Select a value for the filter"),
      })
      .defined(),
    invert: yup.boolean().required(),
  })
  .default(() => ({ id: uuidv4(), field: "", operation: "", values: [] }));

export const segmentSchema = yup
  .object({
    id: yupUUID.required(),
    name: yup.string().nullable(),
    operation: yup
      .mixed<"AND" | "OR">()
      .oneOf(["AND", "OR"])
      .required("Select an operator for the filter"),
    conditions: yup.array().of(segmentConditionSchema),
    description: yup.string().nullable(),
    is_recipient_type: yup.boolean(),
  })
  .default(() => ({ id: uuidv4(), operation: "", conditions: [] }))
  .test("audience", "Segment must have an associated audience", (segment, ctx) => {
    if (!segment) return true;

    const { conditions, is_recipient_type } = segment;

    const audience = conditions?.find((x) => x.field.startsWith("recipient_type_"));
    if (is_recipient_type || !!audience) return true;

    return ctx.createError({
      message: "Segment must have an associated audience",
      path: `segment.audience`,
    });
  });

export type Segment = {
  id: UUID;
  name: string | null;
  description: string | null;
  is_recipient_type: boolean;
  is_default: boolean | null;
  operation: "AND" | "OR";
  conditions: SegmentCondition[];
  created_at: ISO8601 | null;
  deleted_at: ISO8601 | null;
  recipient_type_key: string | null;
  unsaved?: boolean;
};
export type SegmentCondition = yup.InferType<typeof segmentConditionSchema>;

export const makeDefaultSegment = (id: UUID): Segment & {name: string} => ({
  id,
  name: "",
  operation: "AND" as const,
  description: "",
  is_recipient_type: false,
  is_default: false,
  conditions: [
    {
      id: uuidv4(),
      field: "",
      operation: "IS" as const,
      values: [""],
      invert: false,
    },
  ],
  created_at: null,
  deleted_at: null,
  recipient_type_key: null,
});
